const Items = []

const GroupItem = {
  id: '',
  name: '',
  code: ''
}

import { fetchPost, fetchGet } from './fetch'
const url = '/api/v1/group'

const newGroup = (data) => {
  const apiUrl = `${url}/save`
  return fetchPost(apiUrl, data)
    .then((group) => {
      return Promise.resolve(group)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}

const updateGroup = (id, data) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing group id'))
  }
  const apiUrl = `${url}/${id}/save`
  return fetchPost(apiUrl, data)
    .then((group) => {
      return Promise.resolve(group)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}

const deleteGroup = (id) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing group id'))
  }
  const urlById = `${url}/${id}/delete`
  return fetchPost(urlById, {})
    .then((group) => {
      return Promise.resolve(group)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}
const getGroupById = (id) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing group id'))
  }
  const urlById = `${url}/${id}`
  return fetchGet(urlById, {})
    .then((group) => {
      return Promise.resolve(group)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

const getGroup = (q, limit, offset) => {
  const apiUrl = `${url}?offset=${offset}&limit=${limit}`
  return fetchGet(apiUrl, { q, limit, offset })
    .then(({ result, options }) => {
      return Promise.resolve({ groups: result, options })
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}
const syncData = (apiKey) => {
  const apiUrl = `/api/v1/sync/groups`
  const headers = { Authorization: apiKey }
  return fetchGet(apiUrl, {}, headers)
    .then((fetched) => {
      return Promise.resolve(fetched)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export {
  Items,
  GroupItem,
  getGroup,
  newGroup,
  updateGroup,
  deleteGroup,
  getGroupById,
  syncData
}
