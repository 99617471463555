const Items = []

import { fetch, fetchGet } from './fetch'
const url = '/api/v1/trip'

const getTripById = (id) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing trip id'))
  }
  const urlById = `${url}/${id}`
  return fetchGet(urlById, {})
    .then((trip) => {
      return Promise.resolve(trip)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

const getTrip = (q, limit, offset) => {
  return fetchGet(url, { q, limit, offset })
    .then((trip) => {
      // trip.map((a) => {
      //   a.location = `${a.location} ${a.province}`
      //   a.lecturer = `${a.first_name} ${a.last_name}`
      //   a.form = { id: a.form_id, title: a.form_title, data: a.form_data }
      //   return a
      // })
      return Promise.resolve(trip)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}

const getTripReport = (id) => {
  const urlReport = `${url}/${id}/report`
  return fetch(urlReport)
  // .then((trip) => {
  //   return Promise.resolve(trip)
  // })
  // .catch((error) => {
  //   console.error(error)
  //   return Promise.reject(error)
  // })
}

const getActiveTrip = () => {
  return getTrip('', 100, 0)
  // const apiUrl = `${url}/active`
  // return fetchGet(apiUrl, {})
  //   .then((trips) => {
  //     // trip.map((a) => {
  //     //   a.location = `${a.location} ${a.province}`
  //     //   a.lecturer = `${a.first_name} ${a.last_name}`
  //     //   a.form = { id: a.form_id, title: a.form_title, data: a.form_data }
  //     //   return a
  //     // })
  //     return Promise.resolve(trips)
  //   })
  //   .catch((error) => {
  //     console.error(error)
  //     return Promise.reject(error)
  //   })
}

const newTrip = (data) => {
  const urlById = `${url}/save`
  return fetch(urlById, data)
    .then((status) => {
      return Promise.resolve(status)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}

const updateTrip = (id, data) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing trip id'))
  }

  const urlById = `${url}/${id}/save`
  return fetch(urlById, data)
    .then((status) => {
      return Promise.resolve(status)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}

const deleteTrip = (id) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing trip id'))
  }
  const urlById = `${url}/${id}/delete`
  return fetchGet(urlById, {})
    .then((status) => {
      return Promise.resolve(status)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}

const getActivities = (id) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing trip id'))
  }
  const urlById = `${url}/${id}/getActivities`
  return fetchGet(urlById, {})
    .then((status) => {
      return Promise.resolve(status)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}

const checkActivityOpen = (openArr, activities) => {
  return activities.map((item) => {
    item.opened = Array.isArray(openArr) && openArr.indexOf(item.id) != -1
    return item
  })
}
const checkActivityEnable = (enableArr, activities) => {
  return activities.map((item) => {
    if (!Array.isArray(enableArr)) {
      item.enabled = false
      item.enableIndex = -1
    } else {
      const index = enableArr.indexOf(item.id)
      item.enabled = index != -1
      item.enableIndex = index
    }
    return item
  })
}
const sortWithEnableItem = (enableArr, activities) => {
  activities.sort((a, b) => {
    return b.enableIndex == -1 ? -1 : a.enableIndex < b.enableIndex ? -1 : 1
  })
  return activities
}

export {
  Items,
  getTrip,
  getTripById,
  getActiveTrip,
  getActivities,
  getTripReport,
  newTrip,
  updateTrip,
  deleteTrip,
  checkActivityOpen,
  checkActivityEnable,
  sortWithEnableItem
}
