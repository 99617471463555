<template>
  <div class="list-table">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-subheader>กิจกรรม</v-subheader>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-toolbar flat color="white">
              <v-btn color="primary" @click="handleAddTrip">เพิ่ม</v-btn>
              <v-text-field
                id
                name
                flat
                prepend-icon="search"
                placeholder="กิจกรรม"
                v-model="search"
                hide-details
                class="hidden-sm-and-down ml-1"
              ></v-text-field>
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <!-- Data table -->
              <v-data-table
                :loading="loading"
                :headers="complex.headers"
                :search="search"
                :items="complex.items"
                :items-per-page-options="[
                  10,
                  25,
                  50,
                  { text: 'All', value: -1 }
                ]"
                class="elevation-1"
                item-key="name"
                v-model="complex.selected"
                @click:row="handleClick"
              >
                <!-- <template v-slot:item.avatar="{ item }">
                  <v-avatar>
                    <img :src="item.avatar" alt="avatar" size="16" />
                  </v-avatar>
                </template>-->
                <template v-slot:item.action="{ item }">
                  <v-menu>
                    <template v-slot:activator="{ on: menu }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn icon v-on="{ ...menu }">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <span>Action</span>
                      </v-tooltip>
                    </template>
                    <v-list class="pa-0" dense>
                      <v-list-item
                        v-for="action in actions"
                        :key="action.text"
                        @click="action.click(item)"
                      >
                        <v-list-item-icon class="mr-2">
                          <v-icon small>{{ action.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ action.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Dialog edit -->
    <v-dialog v-model="dialogFormTrip" max-width="150vh">
      <v-card>
        <v-toolbar card>สร้างการเยี่ยมชม</v-toolbar>
        <v-card-text>
          <form>
            <input v-if="editMode" type="hidden" v-model="formModel.id" />
            <v-text-field
              v-model="formModel.title"
              :counter="255"
              label="ชื่อการเยี่ยมชม"
              required
            ></v-text-field>
            <v-select
              label="คณะ"
              :items="groups"
              :item-text="'name'"
              :item-value="'id'"
              v-model="formModel.group_id"
            ></v-select>
            <v-select
              label="หลักสูตร"
              :items="courses"
              :item-text="'title'"
              :item-value="'id'"
              v-model="formModel.courses_id"
            ></v-select>
            <v-row>
              <v-col>
                <!-- Start Date -->
                <v-menu
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formModel.start_date"
                      label="วันที่เริ่มอบรม"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formModel.start_date"
                    :show-current="true"
                    color="green lighten-1"
                  ></v-date-picker>
                </v-menu>
                <!-- /Start Date -->
              </v-col>
              <v-col>
                <!-- End Date -->
                <v-menu
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formModel.end_date"
                      label="วันที่สิ้นสุดการอบรม"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formModel.end_date"
                    @input="menuEndDate = false"
                  ></v-date-picker>
                </v-menu>
                <!-- /End Date -->
              </v-col>
            </v-row>

            <v-text-field
              v-model="formModel.period"
              label="ระยะเวลา"
            ></v-text-field>
            <v-text-field
              v-model="formModel.participant_count"
              label="จำนวนผู้เข้าอบรม"
            ></v-text-field>
            <v-text-field
              v-model="formModel.project"
              label="ชื่อโครงการ"
            ></v-text-field>
            <!-- <TextEditor
              v-model="formModel.period"
              label="xxxx"
              rows="3"
            ></TextEditor> -->
            <v-textarea
              v-model="formModel.origin"
              rows="3"
              label="ที่มาและความสำคัญ"
            ></v-textarea>
            <v-textarea
              v-model="formModel.compound"
              rows="3"
              label="องค์ประกอบ"
            ></v-textarea>
            <v-textarea
              v-model="formModel.faculty_staff"
              rows="3"
              label="เจ้าหน้าที่รับคณะ"
            ></v-textarea>
            <v-text-field
              v-model="formModel.budget_source"
              label="แหล่งสนับสนุนงบประมาณ"
            ></v-text-field>
            <v-textarea
              v-model="formModel.schedule"
              rows="5"
              label="กำหนดการ"
            ></v-textarea>
            <v-textarea
              v-model="formModel.summary"
              rows="5"
              label="สรุปเนื้อหาการศึกษาดูงาน"
            ></v-textarea>

            <!-- <v-select
              multiple="true"
              :items="activities"
              :item-text="'title'"
              :item-value="'id'"
              label="กิจกรรม"
            ></v-select> -->
            <!-- v-model="activitiesEnable" -->
            <v-divider class="mt-3 mb-3"></v-divider>
            <v-btn :loading="loading" :disabled="loading" @click="handleSubmit"
              >บันทึก</v-btn
            >
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog detail -->
    <v-dialog v-model="dialog" max-width="150vh" scrollable>
      <v-card>
        <v-toolbar card>รายละเอียดกิจกรรม</v-toolbar>
        <v-card-text>
          <v-row>
            <v-col>
              <h3>รายการ</h3>
              <p>{{ formModel.title }}</p>
              <h3>คณะ</h3>
              <p>{{ formModel.group }}</p>
              <h3>หลักสูตร</h3>
              <p>{{ formModel.courses }}</p>
              <h3>กิจกรรม</h3>
              <ul id="example-1">
                <li v-for="item in formModel.activities" :key="item.id">
                  <v-switch
                    v-model="disabled"
                    class="ma-2"
                    label="item"
                  ></v-switch>
                </li>
              </ul>

              <h3>สถานที่</h3>
              <p>{{ formModel.location }}</p>
              <h3>ระยะเวลา</h3>
              <p>{{ formModel.period }}</p>
              <h3>จำนวนผู้เข้าอบรม</h3>
              <p>{{ formModel.participant_count }}</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false">
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog QR Code -->
    <v-dialog v-model="qrDialog" max-width="60vh">
      <v-card>
        <v-toolbar card>QR Code</v-toolbar>
        <v-card-text>
          <VueQrcode :value="formModel.qrCodeUrl" />
          <h3>
            <a :href="formModel.qrCodeUrl">{{ formModel.qrCodeUrl }}</a>
          </h3>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog activity -->
    <v-dialog v-model="manageActivityDialog" max-width="120vh">
      <v-card>
        <v-toolbar card
          >จัดการกิจกรรม
          <v-progress-circular
            v-if="manageActivityDialogLoading"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-toolbar>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">#</th>
                  <th class="text-center">กิจกรรม</th>
                  <th class="text-center">ใช้งาน</th>
                  <th class="text-center">เปิด/ปิด การประเมิน</th>
                </tr>
              </thead>
              <tbody id="dragTable">
                <tr
                  v-for="item in activities.items"
                  :key="item.id"
                  class="sortableRow sortHandle handle"
                >
                  <td>{{ item.id }}</td>
                  <td>{{ item.title }}</td>
                  <td><v-switch v-model="item.enabled"></v-switch></td>
                  <td><v-switch v-model="item.opened"></v-switch></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="handleUpdateActivities">
            บันทึก
          </v-btn>
          <v-btn color="secondary" @click="manageActivityDialog = false">
            ยกเลิก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import Sortable from 'sortablejs'
import VueQrcode from 'vue-qrcode'
import {
  getTrip,
  newTrip,
  updateTrip,
  deleteTrip,
  // getActivities,
  checkActivityOpen,
  checkActivityEnable,
  sortWithEnableItem
} from '@/api/trip'

import { getActivityByCourse } from '@/api/activity'
import { getGroup } from '@/api/group'
import { getCourse } from '@/api/course'
import { getLocation } from '@/api/location'
// import TextEditor from '@/components/widgets/TextEditor'

import API from '@/api'
export default {
  name: 'TripList',
  components: {
    VueQrcode
    // TextEditor
  },
  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      loading: true,
      dialog: false,
      dialogFormTrip: false,
      qrDialog: false,
      manageActivityDialog: false,
      manageActivityDialogLoading: false,
      editMode: false,
      search: '',
      groups: [],
      courses: [],
      locations: [],
      activities: {
        headers: [
          {
            text: 'กิจกรรม',
            value: 'title'
          },
          {
            text: 'เปิด/ปิด',
            value: 'enable'
          },
          {
            text: 'เปิด/ปิด การประเมิน',
            value: 'enableAssessment'
          }
        ],
        items: []
      },
      formModel: {
        id: '',
        title: '',
        project: '',
        origin: '',
        compound: '',
        faculty_staff: '',
        budget_source: '',
        schedule: '',
        summary: '',
        group_id: '',
        group: '',
        course_id: '',
        course: '',
        location_id: '',
        location: '',
        period: '',
        lecturer_id: [],
        lecturer: '',
        participant_count: 0,
        qrCodeUrl: '',
        activities: []
      },
      complex: {
        selected: [],
        headers: [
          {
            text: '#',
            value: 'id'
          },
          {
            text: 'คณะ',
            value: 'group'
          },
          {
            text: 'หลักสูตร',
            value: 'courses'
          },
          {
            text: 'จำนวน',
            value: 'participant_count'
          },
          {
            text: 'ระยะเวลา',
            value: 'period'
          },
          {
            text: 'เริ่ม',
            value: 'start_date'
          },
          {
            text: 'สิ้นสุด',
            value: 'end_date'
          },
          {
            text: 'จัดการ',
            value: 'action'
          }
        ],
        items: []
      },
      actions: [
        {
          text: 'QR Code',
          icon: 'mdi-qrcode',
          click: this.handleViewQrCode
        },
        {
          text: 'จัดการกิจกรรม',
          icon: 'mdi-pencil',
          click: this.handleViewManageActivites
        },
        {
          text: 'ผลการประเมิน',
          icon: 'mdi-chart-areaspline',
          click: this.handleViewReport
        },
        {
          text: 'ดาวน์โหลดรายงาน',
          icon: 'mdi-download',
          click: this.handleDownloadReport
        },
        {
          text: 'รายละเอียด',
          icon: 'mdi-eye',
          click: this.handleViewItem
        },
        {
          text: 'แก้ไข',
          icon: 'mdi-pencil',
          click: this.handleEditItem
        },
        {
          text: 'ลบ',
          icon: 'mdi-close',
          click: this.handleDeleteItem
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    dragReorder({ oldIndex, newIndex }) {
      const movedItem = this.activities.items.splice(oldIndex, 1)[0]
      this.activities.items.splice(newIndex, 0, movedItem)
    },
    fetchData() {
      this.loading = true
      getGroup('', 100, 0)
        .then(({ groups }) => {
          this.groups = groups
          return getCourse('', 100, 0)
        })
        .then((courses) => {
          this.courses = courses
          return getLocation()
        })
        .then((locations) => {
          this.locations = locations
          return getTrip('', 100, 0)
        })
        .then((trips) => {
          this.complex.items = trips
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleAddTrip() {
      this.editMode = false
      this.dialogFormTrip = true
    },
    handleClick(row) {
      this.handleViewItem(row)
    },
    handleViewQrCode(row) {
      //row
      this.formModel = Object.assign(this.formModel, row)
      this.formModel.qrCodeUrl = API.getBaseUrl(`/t/${row.id}`)
      this.qrDialog = true
    },
    handleViewManageActivites(trip) {
      this.manageActivityDialogLoading = true
      this.manageActivityDialog = true
      this.formModel = trip
      getActivityByCourse(trip.courses_id)
        .then((activities) => {
          activities = checkActivityOpen(trip.activity_open, activities)
          activities = checkActivityEnable(trip.activity_enable, activities)
          this.activities.items = sortWithEnableItem(
            trip.activity_enable,
            activities
          )
          new Sortable(document.querySelector('#dragTable'), {
            draggable: '.sortableRow',
            handle: '.sortHandle',
            onEnd: this.dragReorder
          })
        })
        .finally(() => {
          this.manageActivityDialogLoading = false
        })
    },
    handleUpdateActivities() {
      this.manageActivityDialogLoading = true

      const activity_open = this.activities.items
        .filter((item) => item.opened)
        .map((item) => item.id)
      const activity_enable = this.activities.items
        .filter((item) => item.enabled)
        .map((item) => item.id)
      const data = {
        activity_open,
        activity_enable
      }
      this.formModel.activity_open = activity_open
      this.formModel.activity_enable = activity_enable
      updateTrip(this.formModel.id, data)
        .then((trip) => {
          console.log(trip)
          // const _trip = this.complex.items.find(
          //   (item) => this.formModel.id == item.id
          // )
          this.complex.items = this.complex.items.map((item) => {
            return item.id == this.formModel.id ? this.formModel : item
          })

          this.manageActivityDialog = false
        })
        .catch((error) => {
          console.error(new Error(error))
        })
        .finally(() => {
          this.manageActivityDialogLoading = false
        })
    },
    handleViewReport(row) {
      this.$router.push(`/trip/${row.id}/report`)
    },
    handleDownloadReport(row) {
      window.open(`/api/v1/trip/${row.id}/download.pdf`, '_blank')
    },
    handleViewItem(row) {
      this.formModel = Object.assign(this.formModel, row)
      this.dialog = true
    },
    handleEditItem(row) {
      this.editMode = true
      this.formModel = Object.assign(this.formModel, row)
      this.dialogFormTrip = true
    },
    handleDeleteItem(row) {
      Vue.$confirm({
        title: 'ลบข้อมูล',
        message: 'คุณต้องการลบข้อมูลนี้ใช่หรือไม่',
        button: {
          yes: 'ใช่',
          no: 'ยกเลิก'
        },
        callback: () => {
          deleteTrip(row.id).finally(() => {
            this.loading = false
          })
        }
      })
    },
    handleSubmit() {
      this.loading = true
      if (this.editMode) {
        updateTrip(this.formModel.id, this.formModel)
          .then((resp) => {
            console.log(resp)
            this.$notify({
              type: 'info',
              group: 'notify',
              //   title: 'เกิดข้อผิดพลาด',
              text: 'แก้ไขข้อมูลเรียบร้อยแล้ว'
            })
            this.dialogFormTrip = false
          })
          .catch((err) => {
            console.error(err)
            this.$notify({
              type: 'error',
              group: 'notify',
              title: 'เกิดข้อผิดพลาด',
              text: err.message
            })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        newTrip(this.formModel)
          .then((resp) => {
            console.log(resp)
            //this.trips.push(resp)
            this.$notify({
              type: 'info',
              group: 'notify',
              text: 'เพิ่มข้อมูลเรียบร้อยแล้ว'
            })
            this.dialogFormTrip = false
          })
          .catch((err) => {
            this.$store.snackbar.text = err.message
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.handle {
  cursor: move;
}
</style>
