import { defaultCatch, fetchGet, fetchPost } from './fetch'
const url = '/api/v1/location'
const Location = {
  id: '',
  title: '',
  title_en: '',
  lat: '',
  lng: ''
}
const Items = []
const getLocationById = (id) => {
  const apiUrl = `${url}/${id}`
  if (id === undefined) {
    return Promise.reject('Missing id')
  }

  return fetchGet(apiUrl)
    .then((location) => {
      if (!location) {
        return Promise.reject('Missing location')
      }
      return Promise.resolve(location)
    })
    .catch(defaultCatch)
}
const getLocation = () => {
  return fetchGet(url)
    .then((locations) => {
      return Promise.resolve(locations)
    })
    .catch(defaultCatch)
}

const newLocation = (data) => {
  const apiUrl = `${url}/save`
  return fetchPost(apiUrl, data)
    .then((location) => {
      return Promise.resolve(location)
    })
    .catch(defaultCatch)
}

const updateLocation = (id, data) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing location id'))
  }
  const apiUrl = `${url}/${id}/save`
  return fetchPost(apiUrl, data)
    .then((location) => {
      return Promise.resolve(location)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}
export {
  Items,
  Location,
  getLocation,
  getLocationById,
  newLocation,
  updateLocation
}
